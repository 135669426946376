var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.productName
        ? _c("h4", { staticClass: "text-muted" }, [
            _vm._v(" Reviews for " + _vm._s(_vm.productName) + " "),
          ])
        : _vm._e(),
      _vm.showCharts
        ? [
            _c(
              "div",
              {
                ref: "charts",
                staticClass: "row",
                style: `height: ${_vm.chartHeight}px`,
              },
              [
                _c(
                  "div",
                  { staticClass: "col-md-4 chartRegion" },
                  [
                    _c(
                      "card",
                      { staticClass: "chartRegion", attrs: { type: "chart" } },
                      [
                        _c("template", { slot: "header" }, [
                          _c("h3", { staticClass: "card-category" }, [
                            _vm._v(" Review Ratings "),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "chart-area chartRegion" },
                          [
                            _vm.ratingsPieChart &&
                            !_vm.isGridLoading(_vm.service)
                              ? _c("pie-chart", {
                                  staticClass: "h-100",
                                  attrs: {
                                    "chart-data": _vm.ratingsPieChart.chartData,
                                    "extra-options":
                                      _vm.ratingsPieChart.extraOptions,
                                  },
                                })
                              : _vm._e(),
                            !_vm.ratingsPieChart
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-muted text-center small font-weight-bold",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.isGridLoading(_vm.service)
                                            ? "Loading data"
                                            : "No data"
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.isGridLoading(_vm.service)
                              ? _c("loading-square")
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-8 chartRegion" },
                  [
                    _c(
                      "card",
                      { staticClass: "chartRegion", attrs: { type: "chart" } },
                      [
                        _c("template", { slot: "header" }, [
                          _c("h3", { staticClass: "card-category" }, [
                            _vm._v(" Avg. Rating Over Time "),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "chart-area chartRegion" },
                          [
                            _vm.ratingsLineChart &&
                            !_vm.isGridLoading(_vm.service)
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "h-100 d-flex justify-content-center align-items-center",
                                  },
                                  [
                                    _vm.ratingsLineChart.noData
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-muted small font-weight-bold mb-0",
                                          },
                                          [_vm._v(" Not enough data to chart ")]
                                        )
                                      : _c("line-chart", {
                                          staticClass: "h-100 w-100",
                                          attrs: {
                                            "chart-data":
                                              _vm.ratingsLineChart.chartData,
                                            "extra-options":
                                              _vm.ratingsLineChart.extraOptions,
                                          },
                                        }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.ratingsLineChart
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-muted text-center small font-weight-bold",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.isGridLoading(_vm.service)
                                            ? "Loading data"
                                            : "No data"
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.isGridLoading(_vm.service)
                              ? _c("loading-square")
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c("div", { ref: "sliderBar", staticClass: "slider-divider" }),
          ]
        : _vm._e(),
      _c("base-grid", {
        ref: _vm.gridRef,
        attrs: {
          service: _vm.service,
          "edit-header": true,
          "is-grid-loading": _vm.isGridLoading(_vm.service),
          "is-date-loading": _vm.isDateLoading,
          "grid-state": _vm.gridState(_vm.service),
          "grid-filter": _vm.preFilter,
          "download-options": _vm.downloadOptions(_vm.service),
          "enable-filter-actions": true,
          "enable-row-selection": true,
          "row-selection": "single",
          "enable-select-all": true,
          "suppress-row-click-selection": true,
          "enable-checkbox": true,
          "column-cell-renderers": _vm.columnCellRenderers(_vm.service),
          "column-cell-editors": _vm.columnCellEditors(_vm.service),
          "chart-toggler": "reviews",
          "enable-date-range": true,
        },
        on: {
          "set-grid-state": (payload) =>
            _vm.setGridState({ service: _vm.service, ...payload }),
          "select-download": _vm.onSelectDownload,
          "cell-value-changed": _vm.onCellValueChanged,
          "column-changed": (obj) => _vm.onColumnChanged("Reviews", obj),
          "grid-date-range-changed": _vm.onGridDateRangeChanged,
          "save-grid-filters": _vm.onSaveGridFilters,
          "delete-grid-filters": _vm.onDeleteGridFilters,
          "update-header-text": _vm.onUpdateHeaderText,
          "row-selected": _vm.onRowSelected,
          "grid-update": _vm.onGridUpdate,
          "filter-changed": _vm.onFiltersChanged,
          "filter-cleared": _vm.onFiltersCleared,
          "applied-grid-state": _vm.onAppliedGridState,
        },
        scopedSlots: _vm._u([
          {
            key: "date-range-label-tooltip",
            fn: function () {
              return [
                _c(
                  "el-tooltip",
                  { attrs: { "open-delay": 500 } },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: { "max-width": "200px" },
                        attrs: { slot: "content" },
                        slot: "content",
                      },
                      [
                        _c("p", [
                          _vm._v(" The default date range is "),
                          _c("strong", [_vm._v("Last 7 Days")]),
                          _vm._v(". "),
                        ]),
                        _c("p", [
                          _vm._v(" The start of a week is "),
                          _c("strong", [_vm._v("Monday")]),
                          _vm._v(
                            '. Selecting a date range of "week" would be Monday to Sunday. '
                          ),
                        ]),
                        _c("p", [
                          _vm._v(" Reviews will be filtered on their "),
                          _c("strong", [_vm._v("Published Date")]),
                          _vm._v(". "),
                        ]),
                      ]
                    ),
                    _c("info-icon", {
                      staticClass: "cursor-pointer text-muted rb-icon-sm",
                      attrs: { title: "" },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "grid-toolbar",
            fn: function () {
              return [
                _vm.bulkSelectedItems
                  ? _c(
                      "el-tooltip",
                      {
                        attrs: {
                          effect: "dark",
                          placement: "top",
                          "open-delay": 500,
                        },
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "content" }, slot: "content" },
                          [
                            _vm.bulkSelectedItems &&
                            _vm.bulkSelectedItems.length > _vm.maxBulkAllowed
                              ? _c("div", [
                                  _vm._v(" You can only bulk update "),
                                  _c("code", [
                                    _vm._v(
                                      _vm._s(_vm.maxBulkAllowed) + " reviews"
                                    ),
                                  ]),
                                  _vm._v(
                                    " at a time. Please select fewer reviews. "
                                  ),
                                ])
                              : _c("div", [
                                  _vm._v(
                                    " Bulk update your reviews for " +
                                      _vm._s(
                                        _vm.formatHeaderFieldNames(
                                          _vm.headerFieldNames
                                        )
                                      ) +
                                      ". "
                                  ),
                                ]),
                          ]
                        ),
                        _c(
                          "span",
                          { staticClass: "inline-block" },
                          [
                            _c(
                              "el-popover",
                              {
                                attrs: {
                                  placement: "bottom",
                                  width: "350",
                                  trigger: "manual",
                                },
                                model: {
                                  value: _vm.enableBulkEdit,
                                  callback: function ($$v) {
                                    _vm.enableBulkEdit = $$v
                                  },
                                  expression: "enableBulkEdit",
                                },
                              },
                              [
                                _vm.enableBulkEdit
                                  ? _c("bulk-actions-modal", {
                                      attrs: {
                                        "is-loading": _vm.isBulkLoading,
                                        "is-review": true,
                                        "header-field-names":
                                          _vm.headerFieldNames,
                                        items: _vm.bulkSelectedItems,
                                      },
                                      on: {
                                        cancel: function ($event) {
                                          _vm.enableBulkEdit = false
                                        },
                                        submit: _vm.onSubmitBulkActions,
                                      },
                                    })
                                  : _vm._e(),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      slot: "reference",
                                      type: "info",
                                      plain: "",
                                      icon: "el-icon-edit-outline",
                                      size: "small",
                                      disabled:
                                        _vm.bulkSelectedItems &&
                                        _vm.bulkSelectedItems.length >
                                          _vm.maxBulkAllowed,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.enableBulkEdit = !_vm.enableBulkEdit
                                      },
                                    },
                                    slot: "reference",
                                  },
                                  [
                                    _vm._v(
                                      " Edit " +
                                        _vm._s(_vm.bulkSelectedItems.length) +
                                        " reviews "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("response-drawer", {
        ref: "responseDrawer",
        attrs: {
          data: _vm.responseData,
          "header-field-names": _vm.headerFieldNames,
          "is-review": true,
        },
        on: {
          "update-review": _vm.onUpdateReviewOrQuestionMetadata,
          close: _vm.onCloseResponseDrawer,
          next: _vm.onSelectNext,
          prev: _vm.onSelectPrev,
        },
      }),
      _c("save-filters-modal", {
        attrs: {
          show: _vm.showSaveFiltersModal.show,
          "is-save-filter": _vm.showSaveFiltersModal.isSaveFilter,
          filters: _vm.showSaveFiltersModal.filters,
          "grid-filter": _vm.showSaveFiltersModal.gridFilter,
          "grid-sort": _vm.showSaveFiltersModal.gridSort,
          "is-loading": _vm.isGridFilterLoading,
        },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.showSaveFiltersModal, "show", $event)
          },
          onConfirmSaveGridFilter: (obj) =>
            _vm.onConfirmSaveGridFilter("Reviews", obj),
          onConfirmDeleteGridFilter: (obj) =>
            _vm.onConfirmDeleteGridFilter("Reviews", obj),
          onCancelSaveFilters: _vm.onCancelSaveFilters,
        },
      }),
      _c("dropdown-column-modal", {
        attrs: {
          show: _vm.showDropdownColumnModal.show,
          "column-values": _vm.showDropdownColumnModal.columnValues,
          field: _vm.showDropdownColumnModal.field,
        },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.showDropdownColumnModal, "show", $event)
          },
          onCancelDropdownColumns: _vm.onCancelDropdownColumns,
          onConfirmSaveDropdownColumns: _vm.onConfirmSaveDropdownColumns,
        },
      }),
      _c("grid-download-modal", {
        attrs: {
          show: _vm.gridDownloadModal.show,
          export: _vm.gridDownloadModal.export,
        },
        on: {
          close: _vm.onResetGridDownloadModal,
          submit: _vm.onSubmitGridDownloadModal,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }