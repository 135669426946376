var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.sellerName
        ? _c("h4", { staticClass: "text-muted" }, [
            _vm._v(" Products sold by " + _vm._s(_vm.sellerName) + " "),
          ])
        : _vm._e(),
      _vm.showCharts
        ? [
            _c(
              "div",
              {
                ref: "charts",
                staticClass: "row",
                style: `height: ${_vm.chartHeight}px`,
              },
              [
                _c(
                  "div",
                  { staticClass: "col-md-4 chartRegion" },
                  [
                    _c(
                      "card",
                      {
                        staticClass: "chartRegion",
                        attrs: { type: "chart" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-between align-items-center",
                                    },
                                    [
                                      _c(
                                        "h3",
                                        { staticClass: "card-category mb-0" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.selectedFilterName) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-dropdown",
                                        {
                                          attrs: {
                                            trigger: "click",
                                            placement: "bottom-end",
                                            size: "small",
                                          },
                                          on: {
                                            command: _vm.onDataFilterChanged,
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "rb-text-help text-info",
                                            },
                                            [
                                              _vm._v(" Filter "),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-arrow-down el-icon--right",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "el-dropdown-menu",
                                            {
                                              attrs: { slot: "dropdown" },
                                              slot: "dropdown",
                                            },
                                            _vm._l(
                                              _vm.filterTypes,
                                              function (type, index) {
                                                return _c(
                                                  "el-dropdown-item",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "dropdown-item",
                                                    attrs: {
                                                      command: {
                                                        filterType: type.grid,
                                                        filterName: type.label,
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(type.label) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1506779143
                        ),
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "chart-area chartRegion" },
                          [
                            _vm.ratingsPieChart &&
                            !_vm.isGridLoading(_vm.service)
                              ? _c("pie-chart", {
                                  staticClass: "h-100",
                                  attrs: {
                                    "chart-data": _vm.ratingsPieChart.chartData,
                                    "extra-options":
                                      _vm.ratingsPieChart.extraOptions,
                                    height: 220,
                                  },
                                })
                              : _vm._e(),
                            !_vm.ratingsPieChart &&
                            !_vm.isGridLoading(_vm.service)
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-muted text-center small font-weight-bold",
                                  },
                                  [_vm._v(" No data ")]
                                )
                              : _vm._e(),
                            _vm.isGridLoading(_vm.service)
                              ? [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-muted text-center small font-weight-bold",
                                    },
                                    [_vm._v(" Loading data ")]
                                  ),
                                  _c("loading-square"),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-8 chartRegion" },
                  [
                    _c(
                      "card",
                      { staticClass: "chartRegion", attrs: { type: "chart" } },
                      [
                        _c("template", { slot: "header" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between align-items-center",
                            },
                            [
                              !_vm.lineChartTypeCache
                                ? _c(
                                    "h3",
                                    { staticClass: "card-category mb-0" },
                                    [_vm._v(" Pricebox Line Chart Data ")]
                                  )
                                : _c(
                                    "h3",
                                    { staticClass: "card-category mb-0" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.lineChartTypeTitles[
                                              _vm.lineChartTypeCache
                                            ]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                              _c(
                                "el-dropdown",
                                {
                                  attrs: {
                                    trigger: "click",
                                    placement: "bottom-end",
                                    size: "small",
                                  },
                                  on: { command: _vm.onChartTypeChanged },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "rb-text-help text-info" },
                                    [
                                      _vm._v(" Chart type "),
                                      _c("i", {
                                        staticClass:
                                          "el-icon-arrow-down el-icon--right",
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "el-dropdown-menu",
                                    {
                                      attrs: { slot: "dropdown" },
                                      slot: "dropdown",
                                    },
                                    _vm._l(
                                      _vm.lineChartTypes,
                                      function (type, index) {
                                        return _c(
                                          "el-dropdown-item",
                                          {
                                            key: index,
                                            staticClass: "dropdown-item",
                                            attrs: { command: type.chartName },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(type.label) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "chart-area chartRegion" },
                          [
                            _vm.sellersLineChart &&
                            !_vm.isGridLoading(_vm.service) &&
                            !_vm.isLineChartLoading()
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "h-100 d-flex justify-content-center align-items-center",
                                  },
                                  [
                                    _vm.sellersLineChart.noData
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-muted small font-weight-bold mb-0",
                                          },
                                          [_vm._v(" Not enough data to chart ")]
                                        )
                                      : _c("line-chart", {
                                          staticClass: "h-100 w-100",
                                          attrs: {
                                            "chart-data":
                                              _vm.sellersLineChart.chartData,
                                            "extra-options":
                                              _vm.sellersLineChart.extraOptions,
                                          },
                                        }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.sellersLineChart && _vm.attemptedLineChart
                              ? [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-center text-muted font-weight-bold small mb-0",
                                    },
                                    [_vm._v(" Not enough data to chart ")]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-center text-muted small",
                                    },
                                    [
                                      _vm._v(
                                        " Select up to 10 rows to plot new data in the chart "
                                      ),
                                    ]
                                  ),
                                ]
                              : _vm._e(),
                            !_vm.sellersLineChart && !_vm.attemptedLineChart
                              ? _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-muted text-center small font-weight-bold",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.isGridLoading(_vm.service) ||
                                            _vm.isLineChartLoading()
                                            ? "Loading data"
                                            : "Select chart type to view data"
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.isGridLoading(_vm.service) ||
                            _vm.isLineChartLoading()
                              ? _c("loading-square")
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c("div", { ref: "sliderBar", staticClass: "slider-divider" }),
          ]
        : _vm._e(),
      _c(
        "base-grid",
        {
          ref: _vm.gridRef,
          staticClass: "grid-pricebox",
          attrs: {
            service: _vm.service,
            "edit-header": true,
            "is-grid-loading": _vm.isGridLoading(_vm.service),
            "is-date-loading": _vm.isDateLoading,
            "grid-state": _vm.gridState(_vm.service),
            "grid-filter": _vm.filterState(_vm.service),
            "download-options": _vm.downloadOptions(_vm.service),
            "enable-filter-actions": true,
            "enable-row-selection": true,
            "enable-delete-rows": true,
            "enable-custom-column-edit": true,
            "enable-checkbox": true,
            "suppress-row-click-selection": true,
            "column-cell-renderers": _vm.columnCellRenderers(_vm.service),
            "column-cell-editors": _vm.columnCellEditors(_vm.service),
            "detail-grid-service": "detailedsellers",
            "detail-row-data": _vm.getDetailRowData,
            "chart-toggler": "pricebox",
            "enable-date-range": true,
          },
          on: {
            "set-grid-state": (payload) =>
              _vm.setGridState({ service: _vm.service, ...payload }),
            "grid-date-range-changed": _vm.onGridDateRangeChanged,
            "select-download": _vm.onSelectDownload,
            "cell-value-changed": _vm.onCellValueChanged,
            "column-changed": (obj) => _vm.onColumnChanged("Pricebox", obj),
            "save-grid-filters": _vm.onSaveGridFilters,
            "delete-grid-filters": _vm.onDeleteGridFilters,
            "update-header-text": _vm.onUpdateHeaderText,
            "grid-update": _vm.onGridUpdate,
            "row-selected": _vm.onRowSelection,
            "filter-changed": _vm.onFiltersChanged,
            "filter-cleared": _vm.onFiltersCleared,
          },
        },
        [
          _vm.showCharts
            ? _c(
                "el-tooltip",
                {
                  attrs: { slot: "grid-toolbar", "open-delay": 500 },
                  slot: "grid-toolbar",
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { "max-width": "200px" },
                      attrs: { slot: "content" },
                      slot: "content",
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "compact-tooltips text-white mb-0" },
                        [
                          _vm._v(
                            " A range of 10 rows can be selected at a time to analyze the data in the chart above. "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm.lineChartNumSelected > 0
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "warning", size: "small", plain: "" },
                          on: { click: _vm.quickDeselectRows },
                        },
                        [_vm._v(" Deselect rows ")]
                      )
                    : _c(
                        "el-button",
                        {
                          staticClass: "bg-transparent",
                          attrs: { type: "plain", size: "small" },
                          on: { click: _vm.quickSelectRows },
                        },
                        [_vm._v(" Select rows ")]
                      ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("save-filters-modal", {
        attrs: {
          show: _vm.showSaveFiltersModal.show,
          "is-save-filter": _vm.showSaveFiltersModal.isSaveFilter,
          filters: _vm.showSaveFiltersModal.filters,
          "grid-filter": _vm.showSaveFiltersModal.gridFilter,
          "grid-sort": _vm.showSaveFiltersModal.gridSort,
          "is-loading": _vm.isGridFilterLoading,
        },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.showSaveFiltersModal, "show", $event)
          },
          onConfirmSaveGridFilter: (obj) =>
            _vm.onConfirmSaveGridFilter("Pricebox", obj),
          onConfirmDeleteGridFilter: (obj) =>
            _vm.onConfirmDeleteGridFilter("Pricebox", obj),
          onCancelSaveFilters: _vm.onCancelSaveFilters,
        },
      }),
      _c("grid-download-modal", {
        attrs: {
          show: _vm.gridDownloadModal.show,
          export: _vm.gridDownloadModal.export,
        },
        on: {
          close: _vm.onResetGridDownloadModal,
          submit: _vm.onSubmitGridDownloadModal,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }